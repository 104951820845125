<template>
    <v-field-wrapper
        :id="props.id"
        :hint="hint"
        :label="label"
        :hiddenLabel="hiddenLabel"
        :error="error"
        :disabled="disabled"
        :required="required"
    >
        <template #default="{ bind }">
            <input
                v-bind="bind"
                type="text"
                v-model="dynamicValue"
                :id="props.id"
                :name="props.id"
                :placeholder="props.placeholder ?? undefined"
                :disabled="props.disabled"
                :required="props.required"
                class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :class="[
                    pale || props.disabled
                        ? 'text-gray-400  dark:text-gray-800'
                        : 'text-gray-900  dark:text-white',
                ]"
                @keyup="$emit('keyup', $event)"
            />
        </template>
    </v-field-wrapper>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';

const props = withDefaults(defineProps<{
    id: string;
    label: string;
    hiddenLabel?: boolean;
    hint?: string|null;
    modelValue?: string | null;
    pale?: boolean | null;
    error?: string|null;
    disabled?: boolean;
    placeholder?: string|null;
    required?: boolean;
}>(), {
    pale: false,
    hiddenLabel: false,
    hint: null,
    modelValue: null,
    error: null,
    disabled: false,
    placeholder: null,
    required: false,
});

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const emit = defineEmits<{
  (event: 'update:modelValue', modelValue: string): void;
}>();
</script>

<style scoped></style>
